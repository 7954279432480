import { useState, type FC, useEffect } from 'react'
import classNames from 'classnames'

import { useLockBodyScroll, useResponsive } from 'hooks'
import { Link, NavLink } from 'react-router-dom'
import { Menu, Search } from 'components'
import {
  BurgerIcon,
  ArrowRightNavIcon,
  BurgerCloseIcon,
  HeartStrokeIcon,
  ShopCartIcon,
  UserIcon,
  ProfileIcon,

  // TimeFwIcon,
  // FileExportIcon,
  // PaperPlaneIcon,
  PhoneIcon,
  ExiteIcon,

  // WalletIcon,
  // BellRingIcon,
  // LockIcon,
  NewListIcon,
} from 'assets'

import styles from './Header.module.scss'
import './MobileHeader.scss'
import { ERoutePaths } from 'router/types'
import { useAppSelector } from 'hooks/useTypedSelector'

const Header: FC = () => {
  const userData = localStorage.getItem('userData')
  const [userEmail, setUserEmail] = useState<string>('')
  const isAuthenticated = userData !== null
  const { basketSlice, watchlistSLice } = useAppSelector(state => state)
  const basketQuality = basketSlice.data?.data?.length
  const watchlistQuality = watchlistSLice.data?.length

  const { settingSlice } = useAppSelector(state => state)

  const { isTablet } = useResponsive()

  const [isOpen, setOpen] = useState(false)

  const openMobileNav = () => {
    setOpen(true)
  }

  const closeMobileNav = () => {
    setOpen(false)
  }

  useEffect(() => {
    const userData = localStorage.getItem('userData')

    if (userData) {
      // const parsedData = JSON.parse(userData)
      setUserEmail('')
    }
  }, [])

  useLockBodyScroll(!isOpen)

  const logout = () => {
    localStorage.removeItem('userData')
    window.open('/', '_self')
  }

  const MobileNavBar = () => (
    <div className='mobile__header'>
      {!isAuthenticated ? (
        <div className='wrapper'>
          <div className='pages'>
            <Link onClick={() => setOpen(false)} className='item' to='/categories'>
              {' '}
              <NewListIcon /> Каталог
            </Link>
            {/* <hr />
            <Link className='item' to='/'>
              {' '}
              <TimeFwIcon /> Moї замовлення
            </Link>
            <hr />
            <Link className='item' to='/'>
              {' '}
              <FileExportIcon />
              Експортувати дані
            </Link>

            <Link className='item' to='/'>
              <PaperPlaneIcon /> Прив’язати Телеграм
            </Link> */}
          </div>
        </div>
      ) : (
        <div className='wrapper'>
          <a href='/profile' className='profile__user'>
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <ProfileIcon />
              <div>
                <h1 style={{ color: 'black', fontWeight: 600, fontSize: '14px' }}>Мій профіль</h1>
                <p style={{ color: '#4C5563', fontWeight: 400, fontSize: '14px' }}> {userEmail}</p>
              </div>
            </div>
            <ArrowRightNavIcon />
          </a>

          <div className='pages'>
            {/* <Link className='item' to='/'>
              {' '}
              <TimeFwIcon /> Moї замовлення
            </Link> */}
            {/* <Link className='item' to='/'>
              {' '}
              <WalletIcon /> Баланс
            </Link> */}

            {/* <hr />
            <Link className='item' to='/'>
              {' '}
              <BellRingIcon />
              Сповіщення
            </Link> */}

            {/* <Link className='item' to='/'>
              <LockIcon /> Змінити пароль
            </Link>
            <Link className='item' to='/'>
              {' '}
              <FileExportIcon />
              Експортувати дані
            </Link> */}

            {/* <Link className='item' to='/'>
              <PaperPlaneIcon /> Прив’язати Телеграм
            </Link> */}
            <hr style={{ marginTop: 300 }} />
            <div onClick={logout} style={{ display: 'flex', alignItems: 'center', gap: '10px', color: '#959595' }}>
              {' '}
              <ExiteIcon /> Вийти
            </div>
            <hr />

            <Link className='item' to='/'>
              <PhoneIcon fill='#1A1A1A' /> 0 800 32 32 44
            </Link>

            <div style={{ display: 'flex', gap: '16px' }}></div>
          </div>
        </div>
      )}
    </div>
  )

  return (
    <header className={styles.wrapper}>
      <div className={styles.wrapper__container}>
        <Link to='/'>
          {/* <LogoIcon className={styles.wrapper__logo} /> */}
          <h1
            style={{
              minWidth: 120,
              color: 'white',
              fontWeight: '900',
              fontFamily: 'sans-serif',
              fontSize: 26,
              textTransform: 'capitalize',
            }}
          >
            {settingSlice.data?.name || 'no logo'}
          </h1>
        </Link>
     
     { !isTablet &&
          <>
            <Menu />

            <Search />

            <div className={styles.wrapper__icons_group}>
              <NavLink
                to={ERoutePaths.Saved}
                className={({ isActive }) =>
                  isActive
                    ? classNames(styles.wrapper__icons_group__item, styles.wrapper__icons_group__item__active)
                    : styles.wrapper__icons_group__item
                }
              >
                {watchlistQuality !== 0 && watchlistQuality !== undefined && (
                  <div className='list_quality'>{watchlistQuality}</div>
                )}{' '}
                <HeartStrokeIcon />
                <p>Бажане</p>
              </NavLink>

              <NavLink
                to={ERoutePaths.Basket}
                className={({ isActive }) =>
                  isActive
                    ? classNames(styles.wrapper__icons_group__item, styles.wrapper__icons_group__item__active)
                    : styles.wrapper__icons_group__item
                }
              >
                {basketQuality !== 0 && basketQuality !== undefined && (
                  <div className='list_quality'>{basketQuality}</div>
                )}{' '}
                <ShopCartIcon />
                <p>Кошик</p>
              </NavLink>

           
            </div>
          </> 
}
        {/* )} */}
      </div>

      {isOpen && <MobileNavBar />}
    </header>
  )
}

export default Header
